const ROOTS_DASHBOARD = '/portal';

// ----------------------------------------------------------------------
export const PATH_PAGES = {
  auth: {
    login: '/login',
  },
  general: {
    home: ROOTS_DASHBOARD,
    users: `${ROOTS_DASHBOARD}/users`,
    operations: `${ROOTS_DASHBOARD}/operations`,
    history: `${ROOTS_DASHBOARD}/history`,
  },
  // user: {
  //   list: path(ROOTS_DASHBOARD, '/users/list'),
  //   joiners: path(ROOTS_DASHBOARD, '/users/joiners'),
  //   profile: (uuid: string) => path(ROOTS_DASHBOARD, `/users/${uuid}/profile`),
  // },
  page404: '/404',
  page403: '/403',
};
