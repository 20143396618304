import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
// routes
import { PATH_PAGES } from '../routes/paths';
// components
// import LoadingScreen from '../components/loading-screen';
import { useSelector } from '../redux/store';
//

// ----------------------------------------------------------------------

type GuestGuardProps = {
  children: ReactNode;
};

export default function GuestGuard({ children }: GuestGuardProps) {
  // const isInitialized = true
  const { user, accessToken } = useSelector((state) => state.user);

  if (user.email && accessToken) {
    return <Navigate to={PATH_PAGES.general.home} />;
  }

  // if (!isInitialized) {
  //   return <LoadingScreen />;
  // }

  return <>{children}</>;
}
