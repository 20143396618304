import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import userReducer from './slices/user';

// ----------------------------------------------------------------------

const checkoutPersistConfig = {
  key: 'cadmos-agent',
  storage,
  keyPrefix: 'redux-',
};

export const rootReducer = combineReducers({
  user: persistReducer(checkoutPersistConfig, userReducer),
});
