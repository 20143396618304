import { useState, ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { PATH_PAGES } from 'src/routes/paths';
import { useSelector } from '../redux/store';
// components
import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------

type AuthGuardProps = {
  children: ReactNode;
};

export default function AuthGuard({ children }: AuthGuardProps) {
  const { user, accessToken, loading } = useSelector((state) => state?.user);

  const { pathname } = useLocation();

  const [requestedLocation, setRequestedLocation] = useState<string | null>(null);

  if (loading) {
    return <LoadingScreen />;
  }

  if (!user.email && !accessToken) {
    // if (pathname !== requestedLocation) {
    //   setRequestedLocation(pathname);
    // }
    return <Navigate to={PATH_PAGES.auth.login} />;
  }

  if(!user.role || user.role !== 'agent' && user.role !== 'super'){
    return <Navigate to={PATH_PAGES.page403} />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
}
