import { Suspense, lazy, ElementType } from 'react';
// components
import LoadingScreen from 'src/components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------

// AUTH
export const LoginPage = Loadable(lazy(() => import('src/pages/auth/LoginPage')));

// DASHBOARD: GENERAL
export const Home = Loadable(lazy(() => import('src/pages/home/Home')));

export const Users = Loadable(lazy(() => import('src/pages/users/Users')));
export const Operations = Loadable(lazy(() => import('src/pages/operations/Operations')));
export const History = Loadable(lazy(() => import('src/pages/history/History')));

// Errors
export const Page404 = Loadable(lazy(() => import('src/pages/error/404')));
export const Page403 = Loadable(lazy(() => import('src/pages/error/403')));