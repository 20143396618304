import { Suspense } from 'react';
import { Navigate, useRoutes, Outlet } from 'react-router-dom';
// auth
import AuthGuard from 'src/guards/AuthGuard';
import GuestGuard from 'src/guards/GuestGuard';
// layouts
import CompactLayout from 'src/layouts/compact';
import DashboardLayout from 'src/layouts/dashboard';
import AuthClassicLayout from 'src/layouts/auth/classic';
// PAGES
import LoadingScreen from 'src/components/loading-screen';
import SplashScreen from 'src/components/loading-screen/splash-screen';
import {
  // Auth
  LoginPage,
  // Pages
  Home,
  Users,
  Operations,
  History,
  // Errors
  Page404,
  Page403
} from './elements';
import { PATH_PAGES } from './paths';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    // Main Routes
    {
      element: <Navigate to={PATH_PAGES.auth.login} replace />, index: true,
    },

    // Auth
    {
      path: 'login',
      element: (
        <GuestGuard>
          <AuthClassicLayout>
            <LoginPage />
          </AuthClassicLayout>
        </GuestGuard>
      ),
    },

    // Dashboard
    {
      path: 'portal',
      element: (
        <AuthGuard>
          <DashboardLayout>
            <Suspense fallback={<LoadingScreen />}>
              <Outlet />
            </Suspense>
          </DashboardLayout>
        </AuthGuard>
      ),
      children: [
        // { element: <Navigate to="/portal/home" replace />, index: true },
        { element: <Home />, index: true },
        { path: 'users', element: <Users /> },
        { path: 'operations', element: <Operations /> },
        { path: 'history', element: <History /> },
        // {
        //   path: 'users',
        //   children: [
        //     { element: <Navigate to="/dashboard/users/list" replace />, index: true },
        //     { path: 'list', element: <UserListPage /> },
        //     { path: 'joiners', element: <JoinerListPage /> },
        //     { path: ':uuid/profile', element: <UserProfilePage /> },
        //   ],
        // },
      ],
    },
    {
      element: (
        <CompactLayout>
          <Suspense fallback={<SplashScreen />}>
            <Outlet />
          </Suspense>
        </CompactLayout>
      ),
      children: [
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
      ],
    },
    { path: '*', element: <Navigate to={PATH_PAGES.page404} replace /> },
  ]);
}
