import { createSlice } from '@reduxjs/toolkit';
// ----------------------------------------------------------------------

const initialState: any = {
  user: {},
  accessToken: '',
  loading: false,
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserDetails: (state, action) => {
      state.user = action.payload?.user;
      state.accessToken = action.payload?.token;
    },
    logout: (state: any) => {
      state.loading = false;
      state.user = {};
      state.accessToken = '';
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { logout, setUserDetails } = slice.actions;
